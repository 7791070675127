import {AddComment, CollComment} from "@/Api/Group";
import Storage from "@/util/Storage"
import {GroupSucStore} from "@/views/Group/GroupSuc/Store";
import {CollCommentType} from "@/Api/Group/indexType";

// 添加评论
export function setComment() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(AddComment({userId,token},GroupSucStore.getGroupSucCommentData).then(res=>{
        let commentData = GroupSucStore.getGroupSucCommentData
        commentData.content = ""
        commentData.firstCommentId = ""
        GroupSucStore.SetGroupSucCommentData( commentData )
        GroupSucStore.SetGroupSucCommentListRefresh(true)
        return res.data
    }))
}

// 收藏
export function setColl<T=string|number>(type=1,taskId=0,commentId?:T) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    let upData:CollCommentType = {type,taskId}
    if ( commentId ) upData['commentId'] = commentId + '';
    return Promise.resolve(CollComment({userId,token},upData).then(res=>{
        return res.data
    }))
}
