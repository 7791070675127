
import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import GroupSucStoreType, {CommentType} from "@/views/Group/GroupSuc/Store/indexType";
import {AddCommentType} from "@/Api/Group/indexType";

@Module({
    name:"GroupSuc",
    store,
    dynamic: true,
})
export default class GroupSuc extends VuexModule implements GroupSucStoreType{

    GroupSucCommentList: any[] = [];
    get getGroupSucCommentList(){ return this.GroupSucCommentList }
    @Mutation
    SetGroupSucCommentList(data: any[]): void {
        this.GroupSucCommentList = data
    }

    GroupSucCommentUpData: CommentType={
        pageNo: 1, pageSize: 15, taskId: "",status:"0"
    };
    get getGroupSucCommentUpData(){ return this.GroupSucCommentUpData }
    @Mutation
    SetGroupSucCommentUpData(data: CommentType): void {
        this.GroupSucCommentUpData = data
    }

    GroupSucCommentData: AddCommentType = {
        content: "",
        firstCommentId: "",
        taskId: "",
        toId: ""
    };
    get getGroupSucCommentData(){ return this.GroupSucCommentData }
    @Mutation
    SetGroupSucCommentData(data: AddCommentType): void {
        this.GroupSucCommentData = data
    }
    GroupSucCommentShow = false;
    get getGroupSucCommentShow(){ return this.GroupSucCommentShow }
    @Mutation
    SetGroupSucCommentShow(data: boolean): void {
        this.GroupSucCommentShow = data
    }

    GroupSucCommentListRefresh=false;
    get getGroupSucCommentListRefresh(){ return this.GroupSucCommentListRefresh }
    @Mutation
    SetGroupSucCommentListRefresh(data: boolean): void {
        if ( data ){
            this.GroupSucCommentListRefresh = true
            let time = setTimeout(()=>{
                this.GroupSucCommentListRefresh = false
                clearTimeout(time)
            },100)
        }else{
            this.GroupSucCommentListRefresh = data
        }
    }

    GroupSucRefresh = false
    get getGroupSucRefresh(){ return this.GroupSucRefresh }
    @Mutation
    SetGroupSucRefresh(data: boolean): void {
        if ( data ){
            this.GroupSucRefresh = true
            let time = setTimeout(()=>{
                this.GroupSucRefresh = false
                clearTimeout(time)
            },100)
        }else{
            this.GroupSucRefresh = data
        }
    }
}

export const GroupSucStore = getModule( GroupSuc )
